/* Scrollbar Styles */
::-webkit-scrollbar {
    width: 16px;
    margin-bottom: 3em;
}

/* ... existing scrollbar styles ... */
/* (keeping all the original scrollbar styles from ::-webkit-scrollbar through ::-webkit-scrollbar-corner) */

body {
    background: black;
}

.txtcenter {
    text-align: center;
}

#info {
    font-family: "Courier New";
    font-size: 1em;
    font-weight: 548 !important;
    color: #63de00;
}

.row {
    display: flex;
    font-size: 16pt;
}

.column1 {
    flex: 25%;
    border-radius: 1rem;
}

.column2 {
    flex: 75%;
    border-radius: 1rem;
}

.plus1, .plus2, .plus3 {
    border-radius: 1rem;
}

#table_feed {
    text-align: right;
    font-family: 'Courier New';
    font-size: 0.75em;
    font-weight: 548 !important;
    margin: auto;
}

#table_feed_head {
    text-align: right;
    font-family: 'Courier New';
    font-size: 0.75em;
    font-weight: 548 !important;
    color: white;
    margin: auto;
}

#table_summary {
    text-align: right;
    font-family: "Courier New";
    font-size: 0.75em;
    font-weight: 548 !important;
    margin-left: auto;
    margin-right: auto;
    color: #63de00;
}

#table_summary_head {
    text-align: right;
    font-family: "Courier New";
    font-size: 0.75em;
    font-weight: 548 !important;
    margin-left: auto;
    margin-right: auto;
}

/* Table cell styles */
/* #table_summary td {
    border-bottom: 1px dotted #63de00;
} */

#table_summary td[style*="font-weight: 548"] {
    color: white;
}

/* Prevent right-click context menu */
body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} 